class NavigationSchnelleinstiegKacheln extends HTMLElement {
    
  connectedCallback() {
    this.globaleVariablenDeklarieren();
    this.navigationSchnelleinstiegKachelnSlider.addEventListener('scroll', (event) => { this.scrollHandler(event) }, { once: true });
    this.initKontrollelemente();
    this.initObserver();
  }

  globaleVariablenDeklarieren() {
      this.navigationSchnelleinstiegKachelnSlider = this.querySelector('.navigation-schnelleinstieg-kacheln');
      this.sliderBox = this.querySelector('.slider-box');
      this.erstesSliderElement = this.querySelector('.kategorie:first-of-type');
      this.letztesSliderElement = this.querySelector('.kategorie:last-of-type');
      this.prev = this.querySelector('[interaction="vorherige-bilder-zeigen"]');
      this.next = this.querySelector('[interaction="naechste-bilder-zeigen"]');
  }

  scrollHandler(event) {
      if (event.target.scrollLeft > 0) {
          this.prev.setAttribute('data-status', 'aktiv');
      }
  }

  initKontrollelemente() {
      this.prev.addEventListener('click', event => this.prevKlickHandler(event));
      this.next.addEventListener('click', event => this.nextKlickHandler(event));
  }

  prevKlickHandler(event) {
      this.zurPositionScrollen(this.navigationSchnelleinstiegKachelnSlider, { 
          left: this.navigationSchnelleinstiegKachelnSlider.scrollLeft - this.querySelector('.kategorie').offsetWidth * 2
      });
  }

  nextKlickHandler(event) {
      this.zurPositionScrollen(this.navigationSchnelleinstiegKachelnSlider, { 
          left: this.navigationSchnelleinstiegKachelnSlider.scrollLeft + this.querySelector('.kategorie').offsetWidth * 2
      });
  }

  zurPositionScrollen(element, options) {
      element.scrollTo({
          top: options.top || 0,
          left: options.left || 0,
          behavior: 'smooth'
      });
  }

  initObserver() {
      this.options = {
          root: this.sliderBox,
          threshold: [0.95, 0.96, 0.97, 0.98, 0.99, 1.0]
      };
      this.observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => this.intersectionHandler(entry));
      }, this.options);
      this.querySelectorAll('.kategorie:first-of-type, .kategorie:last-of-type').forEach(element => this.observer.observe(element));
  }

  intersectionHandler(entry) {
      if (entry.target === this.erstesSliderElement && this.statusIstNichtInitial(this.prev)) {
          this.prev.setAttribute('data-status', entry.intersectionRatio === 1 ? 'inaktiv' : 'aktiv');
      }
      if (entry.target === this.letztesSliderElement) {
          this.next.setAttribute('data-status', entry.intersectionRatio === 1 ? 'inaktiv' : 'aktiv');
      }
  }

  statusIstNichtInitial(element) {
      return element.dataset['status'] !== undefined;
  }

}

if (!customElements.get("navigation-schnelleinstieg-kacheln")) {
  customElements.define("navigation-schnelleinstieg-kacheln", NavigationSchnelleinstiegKacheln);
}
